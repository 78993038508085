<template>
  <div class="vipMain">
    <div class="backHome"  v-if="toolsShow == true">
      <span class="backIco" @click="back('prev')"></span>
      <span class="backHomeIco" @click="back('main')"></span>
      {{currentLang.title}}
    </div>
    <div class="content">
      <div class="flex flex__middle" @click="viewDetails(userCode)">
        <div>
          <van-image round width="4rem" height="4rem" :src="imgUrl" />
        </div>
        <div class="ml-10">{{kh}}</div>
      </div>
      <div class="text mt-10">
        <van-cell-group>
          <van-field v-model="messageTitle" ref="messageTitle" :placeholder="currentLang.mailTitle" />
          <!-- <input type="text"  ref="messageTitle" placeholder="标11题" /> -->
        </van-cell-group>
      </div>
      <div class="text mt-10">
        <van-field v-model="message" ref="message" rows="6" autosize type="textarea"
          :placeholder="currentLang.tipInputContent" />
      </div>
      <div class="flex flex__justify" style="display:none;">
        <div></div>
        <div class="flex mt-10">
          <div>
            <img class="icon22" src="../../assets/img/xiaolian.png" />
          </div>
          <div>
            <img class="icon22 ml-10" src="../../assets/img/tupian.png" />
          </div>
        </div>
      </div>
      <div class="btn" @click="sendEmail()">{{currentLang.send}}</div>
    </div>
  </div>
</template>

<script>
  import {
    RequestApi
  } from "@/apis/demo.js";
  import Cookies from 'js-cookie';
  import {
    getQueryVariable
  } from "@/utils/request";
  export default {
    name: "",
    props: {},
    data() {
      return {
        messageTitle: "",
        kh:'',
        message: "",
        userName: "",
        userHeadImg: '',
        toolsShow: true, //显示工具栏
        languageLib: {
          lang_1: {
            title: 'Mail',
            mailTitle: 'Title',
            tipInputContent: 'Please Input Content',
            send: 'SEND',
            tipUser: 'choose email recipient',
            tipTitle: 'please input title',
            tipContent: 'please fill the content'
          },
          lang_2: {
            title: '邮件',
            mailTitle: '标题',
            tipInputContent: '请输入内容',
            send: '发送',
            tipUser: '请选择您要发邮件的用户',
            tipTitle: '请填写标题',
            tipContent: '请填写内容'
          },
          lang_3: {
            title: '郵件',
            mailTitle: '標題',
            tipInputContent: '請輸入内容',
            send: '發送',
            tipUser: '請選擇您要發送郵件的用戶',
            tipTitle: '請填寫標題',
            tipContent: '請填寫内容'
          }
        },
        currentLang: {

        }
      };
    },
    components: {},
    beforeMount() {
      const language_id = Cookies.get('language_id') || 2; //2022-3-9 按照客户要求默认改为简体字 //客户现在要求默认是繁体
      this.currentLang = this.languageLib['lang_2' ];
    },
    mounted() {
      //加载用户的头像和名字
      var url = location.href;
      this.userCode = decodeURI(getQueryVariable(url,
        "userCode"));
      console.log("================>" + this.userCode)

      this.deviceType = getQueryVariable(url,
        "deviceType");
      if (this.deviceType == "pc") {
        //如果是pc，那么就是电脑调用，不显示对应的顶部导航
        this.toolsShow = false;
      }

      //加载这个会员的详情
      this.getUserInfo(this.userCode)
    },
    methods: {
      viewDetails(userCode) {
        this.$router.push({
          path: '/userDet',
          query: {
            'userCode': userCode
          }
        });
      },
      back(where) {
        if (where == "main") {
          this.$router.push({
            name: "main",
          });
        }
        if (where == "prev") {
          this.$router.back()
        }
      },
      async getUserInfo(userCode) {
        //获取会员部分信息
        var that = this;
        var uri = "";
        uri = this.$global.domain + "/tools/MallSelect.ashx";
        var param = {
          action: "getLikeUserInfo2021",
          user: Cookies.get("kh"),
          queryUser: userCode, //查询指定会员的时候使用，必须是权限允许的会员
          sitenum: '18777777777',
          token: Cookies.get("token"),
        };
        var result = await RequestApi.post(uri, param);
        console.log(result);
        if (result.status == "1") {
          that.kh = result.kh
          that.userName = result.name
          that.userHeadImg = result.avatar

        } else {

        }
      },
      async getEmailList() {
        //发邮件列表
        var that = this;
        var uri = "";
        uri = this.$global.domain + "/tools/post_2.ashx?action=getUemails";
        var param = {
          action: "getUemails",
          page: '1',
          msgType: "2", //5 是聊天  2是邮件
          user: Cookies.get("kh"),
          mId: "1", //1,全部，2已读
          limit: 100,
          sitenum: '18777777777',
          token: Cookies.get("token"),
          code: Cookies.get("code"),
        };
        var result = await RequestApi.post(uri, param);
        console.log("================>接口返回");
        console.log(result);
        if (result.status == "1") {


        } else {
          Toast(result.msg);
        }
      },
      async sendEmail() {
        //发邮件
        if (this.userCode == "") {
          Toast(this.currentLang.tipUser);
          return;
        }
        if (this.messageTitle == "") {
          Toast(this.currentLang.tipTitle);
          return;
        }
        if (this.message == "") {
          Toast(this.currentLang.tipContent);
          return;
        }
        var that = this;
        var uri = "";
        uri = this.$global.domain + "/tools/post_2.ashx?action=bSendMsg";
        var param = {
          action: "bSendMsg",
          msgType: "2", // 5是聊天  2是站内信
          pname: this.userCode, //收信人
          sname: Cookies.get("kh"), //发信人
          ntitle: this.messageTitle, //标题
          ncontent: this.message, //正文
          sitenum: '18777777777',
          token: Cookies.get("token"),
          code: Cookies.get("code"),
        };
        var result = await RequestApi.post(uri, param);
        console.log("================>接口返回");
        console.log(result);
        if (result.status == "1") {
          Toast(result.msg);
          //延时跳转 让顾客看清提示
          setTimeout(() => {
            //需要延迟的代码 :3秒后延迟跳转到首页，可以加提示什么的
            //this.$router.push("home");
            this.$router.go(-1);
            //延迟时间：3秒
          }, 1500)

        } else {
          Toast(result.msg);
        }
      },
    },
    computed: {
      //给头像传值
      imgUrl: function() {
        return this.userHeadImg;
      }
    },
    watch: {},
  };
</script>

<style lang="less">
  .vipMain {
    min-height: 300px;
    padding-bottom: 120px;
    padding-top: 50px;
  }

  .content {
    margin-left: 15px;
    margin-right: 15px;

    .btn {
      width: 92%;
      height: 52px;
      background: #fd4c54;
      border-radius: 30px;
      text-align: center;
      line-height: 52px;
      color: #fff;
      font-size: 18px;
      position: fixed;
      bottom: 10px;
    }
  }

  .text {
    .van-cell {
      background-color: #f5f5ff;
      border-radius: 30px;
    }
  }

  .backHome {
    height: 38px;
    width: 100%;
    line-height: 38px;
    position: fixed;
    background-color: white;
    box-shadow: 2px 2px 6px #bfb8b8;
    display: inline-block;
    top: 0px;
    text-align: center;
    font-size: 16px;
    color: #222;
    z-index: 999;

    span {
      position: absolute;
      // border: 1px red dashed;
    }

    .backIco {
      left: 15px;
      top: 10px;
      width: 18px;
      height: 18px;
      display: inline-block;
      background-position: center;
      background-size: 16px 16px;
      background-repeat: no-repeat;
      background-image: url("../../assets/img/back2.png");
    }

    .backHomeIco {
      left: 43px;
      top: 10px;
      width: 18px;
      height: 18px;
      display: inline-block;
      background-position: center;
      background-size: 16px 16px;
      background-repeat: no-repeat;
      background-image: url("../../assets/img/home.png");
    }
  }
</style>
